<template>
  <div class="s-orders-page page">
    <!-- <Tab :sources="sources" :current-source="source" v-if="sources.length > 1" /> -->
    <p v-if="fetching"><Loading /></p>
    <div class="page__title">
      {{ getPageConfigBySource(source).form_title || '' }}
    </div>
    <div
        class="page__desc"
        v-html="getPageConfigBySource(source).form_desc || ''"
        ></div>
    <div  class="page__content" v-if="getPageConfigBySource(source).content" v-html="getPageConfigBySource(source).content || ''"></div>
    <template v-else>
      <div
        class="
          d-flex
          s-p-3 s-border-b s-border-gray-light
          bg-white
          justify-content-between
          align-items-center
        "
        v-if="showDateFilter"
      >
        <datepicker
          placeholder="請輸入查詢開始時間"
          @input="
            (e) => {
              startAt = fixDate(e);
            }
          "
          :value="startAt"
          bootstrap-styling
          format="yyyy/MM/dd"
          :language="zh"
          class="p-0 field__input mr-3"
          input-class="px-3 text-center"
          :disabled-dates="disabledDates"
          popover-align="center"
          v-if="
            sourceConfig.filter_by_date &&
            sourceConfig.filter_by_date.show_calendar
          "
          @selected="dateStartChange"
        >
        </datepicker>
        <template
          v-if="
            sourceConfig.filter_by_date &&
            sourceConfig.filter_by_date.show_calendar &&
            !sourceConfig.filter_by_date.show_option
          "
        >
          <div class="mr-3">─</div>
          <datepicker
            placeholder="請輸入查詢結束時間"
            @input="
              (e) => {
                endAt = fixDate(e);
              }
            "
            :value="endAt"
            bootstrap-styling
            format="yyyy/MM/dd"
            :language="zh"
            class="p-0 field__input"
            input-class="px-3 text-center"
            position="center"
            :disabled-dates="disabledDates"
            @selected="dateEndChange"
          >
          </datepicker>
        </template>

        <select
          class="form-control"
          v-if="
            sourceConfig.filter_by_date &&
            sourceConfig.filter_by_date.show_option
          "
          @change="dateOptionChange"
          :value="daysDiff"
        >
          <option
            v-for="i in sourceConfig.filter_by_date.options"
            :key="i.key"
            :value="i.key"
          >
            {{ i.title }}
          </option>
        </select>
      </div>
      <template v-if="orders.length">
        <ul class="s-p-3">
          <li class="mb-3" v-for="(order, i) in orders" :key="i">
            <Item
              :order="order"
              :show-image="sourceConfig.list_layout.show_image"
              :field-setting="sourceConfig.field_setting"
            />
          </li>
        </ul>
        <Pagination :current="page" :last="lastPage" />
      </template>
      <div class="s-pt-40 s-text-center" v-else>
        <img class="s-mb-3" src="@/assets/images/liff/empty.svg" />
        <p class="s-text-gray">沒有訂單</p>
        <p class="s-text-gray">訂單小幫手每 3 分鐘會更新一次訂單狀態唷</p>
      </div>
    </template>
  </div>
</template>

<script>
// import Tab from '@/components/Page/Liff/OrderList/Tab'
import Item from "@/components/Page/Liff/OrderList/Item";
import Pagination from "@/components/Page/Liff/OrderList/Pagination";
import orderListPage from "@/mixins/liff/orderList/page";
import { zh } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  mixins: [orderListPage],
  components: {
    //Tab,
    Item,
    Pagination,
    Datepicker,
  },
  data: () => ({
    zh,
    orders: [],
    startAt: null,
    endAt: moment().format("YYYY-MM-DD"),
    lastPage: 1,
    disabledDates: { from: new Date() },
    daysDiff: 0,
  }),
  computed: {
    page() {
      return +this.$route.query.page || 1;
    },
    showDateFilter() {
      let filter = this.sourceConfig.filter_by_date;
      return (
        filter &&
        (filter.show_option != undefined ||
          filter.show_calendar != undefined) &&
        (filter.show_option == true || filter.show_calendar == true)
      );
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    source: "init",
    page: "init",
  },
  methods: {
    getPageConfigBySource(key) {
      return this.moduleConfig[`${key}_page`] || {} ;  
    },
    init() {
      let filter = this.sourceConfig.filter_by_date;
      if (filter) {
        if (filter.show_option && !filter.show_calendar) {
          this.daysDiff = filter.options[0].key;
          this.endAt = moment().format("YYYY-MM-DD");
          if (this.$route.query.startAt) {
            this.daysDiff = moment(this.$route.query.endAt).diff(
              this.$route.query.startAt,
              "days"
            );
          }

          this.startAt =
            this.$route.query.startAt ||
            moment().subtract(this.daysDiff, "days").format("YYYY-MM-DD");
        } else if (filter.show_option) {
          this.endAt = this.$route.query.endAt || moment().format("YYYY-MM-DD");
          this.startAt =
            this.$route.query.startAt ||
            moment()
              .subtract(filter.options[0].key, "days")
              .format("YYYY-MM-DD");

          this.daysDiff =
            this.$route.query.endAt && this.$route.query.startAt
              ? moment(this.$route.query.endAt).diff(
                  this.$route.query.startAt,
                  "days"
                )
              : filter.options[0].key;
        } else if (!filter.start_at) {
          this.startAt =
            this.$route.query.startAt ||
            moment().subtract(6, "days").format("YYYY-MM-DD");
        }
      }

      this.fetch();
    },
    async fetch() {
      this.orders = [];
      this.lastPage = 1;

      let payload = {
        provider: this.source,
        page: this.page,
      };

      if (this.showDateFilter) {
        if (this.startAt == null || this.endAt == null) return;

        if (
          this.startAt != this.$route.query.startAt ||
          this.endAt != this.$route.query.endAt
        ) {
          this.$router.push(
            {
              path: this.$route.path,
              query: {
                ...this.$route.query,
                startAt: this.startAt,
                endAt: this.endAt,
              },
            },
            () => {
              this.fetch();
            }
          );

          return;
        }

        this.payload.start_at = this.startAt;
        this.payload.end_at = this.endAt;
      }

      if (this.sourceConfig?.filter_by_date?.start_at) {
        this.startAt = moment(this.sourceConfig.filter_by_date.start_at).diff(this.startAt, 'days') > 0 ?
          moment(this.sourceConfig.filter_by_date.start_at).format("YYYY-MM-DD") :
          this.startAt ?? moment(this.sourceConfig.filter_by_date.start_at).format("YYYY-MM-DD");
        payload.start_at = this.startAt
      }

      try {
        const { data, lastPage } = await this.$api.getList(payload);
        this.orders = data;
        this.lastPage = lastPage;
      } catch (error) {
        let errorMessage = error.response?.data?.message ?? null;
        this.$swal("錯誤", errorMessage, "error");
        return;
      }
    },
    dateStartChange(value) {
      let filter = this.sourceConfig.filter_by_date;

      this.startAt = moment(value).format("YYYY-MM-DD");

      if (filter.show_option) {
        this.endAt = (
          moment().diff(moment(value).add(this.daysDiff, "days")) < 0
            ? moment()
            : moment(value).add(this.daysDiff, "days")
        ).format("YYYY-MM-DD");
      } else {
        if (moment(this.endAt).diff(moment(value)) < 0) {
          this.endAt = moment().format("YYYY-MM-DD");
        }
      }

      this.fetch();
    },
    dateEndChange(value) {
      this.endAt = moment(value).format("YYYY-MM-DD");

      if (moment(value).diff(moment(this.startAt)) < 0) {
        this.startAt = moment(value).format("YYYY-MM-DD");
      }

      this.fetch();
    },
    dateOptionChange(e) {
      let value = e.target.value;

      let filter = this.sourceConfig.filter_by_date;

      if (filter.show_calendar) {
        if (moment().diff(moment(this.startAt).add(value, "days")) < 0) {
          this.startAt = moment().subtract(value, "days").format("YYYY-MM-DD");

          this.endAt = moment().format("YYYY-MM-DD");
        } else {
          this.endAt = moment(this.startAt)
            .add(value, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        this.startAt = moment().subtract(value, "days").format("YYYY-MM-DD");
        this.endAt = moment().format("YYYY-MM-DD");
      }

      this.daysDiff = value;

      this.fetch();
    },
    fixDate(e) {
      return moment(e).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    padding-top: 10px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
select {
  border: 1px solid var(--s-gray-dark);
  border-radius: 0.5rem;
  padding: 0 1rem;
}
.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
    &:read-only {
      background-color: #f0f0f0;
    }
  }
}
.s-orders-tab {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.s-orders-notify {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--s-white);
  padding: 1rem 0;
  text-align: center;
  z-index: 1;
}
</style>
