<template>
  <router-link
    :to="{
      name: 'LiffOrderListShow',
      params: { id: order.id },
      query: { source: $route.query.source },
    }"
    v-slot="{ navigate }"
    custom
  >
    <div class="s-order-item" @click="navigate">
      <div class="s-order-item-image" v-if="showImage && image">
        <img :src="image" />
      </div>
      <template v-if="listSetting">
        <div class="s-order-item-info">
          <div class="s-space-y-2 s-mr-auto" v-if="leftField">
            <template v-for="(field, i) in leftField">
              <p
                :key="field.key"
                :class="leftClass(i)"
              >
                {{ field.value }}{{ getOrderData(field.key) }}
              </p>
            </template>
          </div>
          <div class="s-flex s-flex-col s-items-end" v-if="rightField">
            <template v-for="(field, i) in rightField">
              <p :key="field.key" :class="rightClass(i)">
                <span class="s-tag" v-if="i == 0">{{ field.value }}{{ getOrderData(field.key) }}</span>
                <template v-else>{{ field.value }}{{ getOrderData(field.key) }}</template>
              </p>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="s-order-item-info">
          <div class="s-space-y-2 s-mr-auto">
            <p class="s-text-gray-darker s-font-semibold">
              訂單編號：{{ order.order_number }}
            </p>
              <p class="s-text-gray s-text-xs s-whitespace-nowrap">
                {{ datetime }}
              </p>
          </div>
          <div class="s-flex s-flex-col s-items-end">
            <p>
              <span class="s-tag">{{ order.order_status }}</span>
            </p>
            <p class="s-text-primary">
              <span class="s-text-sm">NT$ </span>
              <span class="s-font-bold">{{ $currency(order.amount, '') }}</span>
            </p>
          </div>
        </div>
      </template>
    </div>
  </router-link>
</template>

<script>
import moment from "moment";

export default {
  inject: ["$currency"],
  props: {
    order: {
      type: Object,
      required: true,
    },
    fieldSetting: {
      type: Object,
      required: false,
    },
    showImage: Boolean,
  },
  computed: {
    image() {
      return null;
    },
    datetime() {
      return moment(this.order.order_at).format("YYYY-MM-DD HH:mm");
    },
    listSetting() {
      return this.fieldSetting?.list ?? false;
    },
    leftField() {
      if (this.listSetting) {
        return this.listSetting.filter((field) => field.position === "left");
      }
      return [];
    },
    rightField() {
      if (this.listSetting) {
        return this.listSetting.filter((field) => field.position === "right");
      }
      return [];
    },
  },
  methods: {
    getOrderData(key) {
      if (key === 'order_at') return this.datetime
      return this.order[key] ?? "";
    },
    leftClass(i) {
      if (i == 0) {
        return "s-text-gray-darker s-font-semibold";
      } else if (i == 1) {
        return "s-text-gray s-text-xs s-whitespace-nowrap";
      } else {
        return "s-text-gray s-text-xs";
      }
    },
    rightClass(i) {
      if (i == 1) {
        return "s-text-primary";
      } else {
        return "s-text-gray s-text-xs";
      }
    },
  },
};
</script>

<style  scoped>

.s-order-item {

  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--s-white);

  box-shadow: rgb(from var(--s-primary) r g b / .5)  0px 2px 8px 0px;
  border-radius: 5px;
}

.s-order-item-image {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 0;
  width: 5rem;
  height: 5rem;
}

.s-order-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s-order-item-info {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 1rem;
}
</style>
