var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-orders-page page"},[(_vm.fetching)?_c('p',[_c('Loading')],1):_vm._e(),_c('div',{staticClass:"page__title"},[_vm._v(" "+_vm._s(_vm.getPageConfigBySource(_vm.source).form_title || '')+" ")]),_c('div',{staticClass:"page__desc",domProps:{"innerHTML":_vm._s(_vm.getPageConfigBySource(_vm.source).form_desc || '')}}),(_vm.getPageConfigBySource(_vm.source).content)?_c('div',{staticClass:"page__content",domProps:{"innerHTML":_vm._s(_vm.getPageConfigBySource(_vm.source).content || '')}}):[(_vm.showDateFilter)?_c('div',{staticClass:"\n        d-flex\n        s-p-3 s-border-b s-border-gray-light\n        bg-white\n        justify-content-between\n        align-items-center\n      "},[(
          _vm.sourceConfig.filter_by_date &&
          _vm.sourceConfig.filter_by_date.show_calendar
        )?_c('datepicker',{staticClass:"p-0 field__input mr-3",attrs:{"placeholder":"請輸入查詢開始時間","value":_vm.startAt,"bootstrap-styling":"","format":"yyyy/MM/dd","language":_vm.zh,"input-class":"px-3 text-center","disabled-dates":_vm.disabledDates,"popover-align":"center"},on:{"input":function (e) {
            _vm.startAt = _vm.fixDate(e);
          },"selected":_vm.dateStartChange}}):_vm._e(),(
          _vm.sourceConfig.filter_by_date &&
          _vm.sourceConfig.filter_by_date.show_calendar &&
          !_vm.sourceConfig.filter_by_date.show_option
        )?[_c('div',{staticClass:"mr-3"},[_vm._v("─")]),_c('datepicker',{staticClass:"p-0 field__input",attrs:{"placeholder":"請輸入查詢結束時間","value":_vm.endAt,"bootstrap-styling":"","format":"yyyy/MM/dd","language":_vm.zh,"input-class":"px-3 text-center","position":"center","disabled-dates":_vm.disabledDates},on:{"input":function (e) {
              _vm.endAt = _vm.fixDate(e);
            },"selected":_vm.dateEndChange}})]:_vm._e(),(
          _vm.sourceConfig.filter_by_date &&
          _vm.sourceConfig.filter_by_date.show_option
        )?_c('select',{staticClass:"form-control",domProps:{"value":_vm.daysDiff},on:{"change":_vm.dateOptionChange}},_vm._l((_vm.sourceConfig.filter_by_date.options),function(i){return _c('option',{key:i.key,domProps:{"value":i.key}},[_vm._v(" "+_vm._s(i.title)+" ")])}),0):_vm._e()],2):_vm._e(),(_vm.orders.length)?[_c('ul',{staticClass:"s-p-3"},_vm._l((_vm.orders),function(order,i){return _c('li',{key:i,staticClass:"mb-3"},[_c('Item',{attrs:{"order":order,"show-image":_vm.sourceConfig.list_layout.show_image,"field-setting":_vm.sourceConfig.field_setting}})],1)}),0),_c('Pagination',{attrs:{"current":_vm.page,"last":_vm.lastPage}})]:_c('div',{staticClass:"s-pt-40 s-text-center"},[_c('img',{staticClass:"s-mb-3",attrs:{"src":require("@/assets/images/liff/empty.svg")}}),_c('p',{staticClass:"s-text-gray"},[_vm._v("沒有訂單")]),_c('p',{staticClass:"s-text-gray"},[_vm._v("訂單小幫手每 3 分鐘會更新一次訂單狀態唷")])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }